import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from "@angular/router";

// secure pages
import {SecureComponent} from './secure.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {ProfileComponent} from './profile/profile.component';

const routes: Routes = [

    // secure pages
    {
        path: '',
        component: SecureComponent,
        children: [
            {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
            {path: 'dashboard', component: DashboardComponent, title: "Dashboard"},
            {path: 'profile', component: ProfileComponent, title: "Profile"}
        ]
    }

];

@NgModule({
    declarations: [
        SecureComponent,
        DashboardComponent,
        ProfileComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule
    ]
})
export class SecureModule {
}
