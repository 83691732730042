import {Component} from '@angular/core';

@Component({
    selector: 'app-secure',
    templateUrl: './secure.component.html',
    styleUrl: './secure.component.scss'
})
export class SecureComponent {

}
